import gql from 'graphql-tag';

export const FETCH_TASKS = gql`
  query FETCH_TASKS($ehpadId: ID!) {
    tasks(ehpadId: $ehpadId) {
      id
      title
      description
      taskCategory {
        id
        title
        description
        icon
      }
    }
  }
`;

export const FIND_TASK = gql`
  query FIND_TASK($id: ID!) {
    findTask(id: $id) {
      id
      title
      description
      taskCategory {
        id
        title
        description
        icon
      }
    }
  }
`;
