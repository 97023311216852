<template>
  <b-container fluid>
    <div v-if="currentEhpadForDashboard">
      <div class="d-flex justify-content-center align-items-center mb-3">
        <span class="text-center">
          <h3 class="m-0" style="display: inline-block;margin: 0;">
            Tableau de bord
          </h3>
        </span>
      </div>
      <hr />

      <div class="d-print-none">
        <li class="text-center" style="list-style-type:none;"><a href="#tests-charts-date">Moyenne des tests (par date)</a></li>
        <li class="text-center" style="list-style-type:none;"><a href="#tests-charts-index">Moyenne des tests (par index)</a></li>
        <hr />
      </div>

      <a id="tests-charts-date"></a>
      <ehpad-tests-chart-results-by-date/>

      <a id="tests-charts-index"></a>
      <ehpad-tests-chart-results-by-index/>

    </div>

    <div class="text-center" style="color: #469AE0;" v-else-if="currentEhpadForDashboard === null">
      <h2>
        <i class="fa fa-spin fa-circle-notch" />
        Chargement...
      </h2>
    </div>

  </b-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
import EhpadTestsChartResultsByDate from '../../components/dashboard/EhpadTestsChartResultsByDate.vue';
import EhpadTestsChartResultsByIndex from '../../components/dashboard/EhpadTestsChartResultsByIndex.vue';

export default {
  name: 'EhpadDashboard',
  components: {
    EhpadTestsChartResultsByDate,
    EhpadTestsChartResultsByIndex,
  },
  data() {
    return {
      currentEhpadForDashboard: {
        date: null,
        index: null,
        tests: null,
      },
    };
  },
  methods: {
    ...mapActions(['findEhpad']),
  },
  computed: {
    ...mapGetters({
      currentUser: 'user',
      currentEhpad: 'ehpad',
    }),
  },
  created() {
    moment.locale('fr-FR');
  },
  watch: {
    currentEhpad: {
      handler() {
        if (this.currentEhpad !== null) {
          this.findEhpad(this.currentEhpad.id);
        }
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>

:target {
  display: block;
  position: relative;
  top: -11.5rem;
  visibility: hidden;
}

</style>
