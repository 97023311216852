<template>
  <b-container fluid>
    <b-form>
      <b-row>
        <b-col cols="4">
          <label for="inline-form-input-title" class="pr-4 text-nowrap">Titre de la catégorie d'activité :</label>
        </b-col>
        <b-col cols="8">
            <b-form-input
              id="inline-form-input-title"
              size="sm"
              type="text"
              v-model="editableTaskCategory.title"
              :class="{ 'is-invalid': $v.editableTaskCategory.title.$anyError }"
            />
            <b-form-invalid-feedback>Valeur requise (50 caractères max)</b-form-invalid-feedback>
        </b-col>
      </b-row>

      <b-row class="pt-4">
        <b-col cols="4">
          <label for="inline-form-input-description" class="pr-4 text-nowrap">Description de la catégorie d'activité :</label>
        </b-col>
        <b-col cols="8">
            <b-form-textarea
              id="inline-form-input-description"
              size="sm"
              type="text"
              rows="6"
              v-model="editableTaskCategory.description"
            />
        </b-col>
      </b-row>

      <b-row class="pt-4">
        <b-col cols="4">
          <label for="inline-form-input-icon" class="pr-4 text-nowrap">Icone pour la catégorie d'activité :</label>
          <p>Icones disponibles <a href="https://fontawesome.com/v5/search" target="_blank">ICI</a></p>
        </b-col>
        <b-col cols="6">
            <b-form-input
              id="inline-form-input-icon"
              size="sm"
              type="text"
              v-model="editableTaskCategory.icon"
            />
        </b-col>
        <b-col cols="2">
          Icone : <i :class="editableTaskCategory.icon" />
        </b-col>
      </b-row>

      <div v-if="error" style="color: red;" class="text-center mt-2">
        <p v-for="(error, i) of error.graphQLErrors" :key="i">
          {{ error.message }}
        </p>
      </div>

      <b-button class="d-flex mx-auto mt-4" variant="primary" size="sm" @click.prevent="updateTaskCategory">
        Valider
      </b-button>
    </b-form>

  </b-container>
</template>

<script>

import { mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required, maxLength } from 'vuelidate/lib/validators';

export default {
  mixins: [validationMixin],
  data() {
    return {
      error: null,
      editableTaskCategory: {
        title: '',
        description: '',
        icon: '',
      },
    };
  },
  created() {
    if (this.taskCategory !== null) {
      this.duplicateTaskCategory();
    }
  },
  watch: {
    taskCategory: {
      handler() {
        if (this.taskCategory !== null) {
          this.duplicateTaskCategory();
        }
      },
      immediate: true,
    },
  },
  validations: {
    editableTaskCategory: {
      title: {
        required,
        maxLength: maxLength(50),
      },
    },
  },
  methods: {
    duplicateTaskCategory() {
      this.editableTaskCategory = { ...this.taskCategory };
    },
    async updateTaskCategory() {
      this.$v.editableTaskCategory.$touch();
      if (this.$v.$anyError) return;
      await this.$store.dispatch('updateTaskCategory', {
        title: this.editableTaskCategory.title,
        icon: this.editableTaskCategory.icon,
        id: this.editableTaskCategory.id,
        description: this.editableTaskCategory.description,
      }).then((result) => {
        this.$router.push({
          path: `/ehpads/${this.currentEhpad.id}/task_categories`,
        });
      }).catch((error) => {
        this.error = error;
        this.flashMessage.show({
          status: 'error',
          title: error.graphQLErrors[0].extensions.code,
          message: error.graphQLErrors[0].message,
        });
      });
    },
  },
  computed: {
    ...mapGetters({
      currentEhpad: 'ehpad',
      taskCategory: 'taskCategory',
    }),
  },
};
</script>

<style lang="scss">

.multiselect__tags {
  border: 1px solid #ced4da !important;
}

</style>
