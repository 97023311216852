<template>
  <b-container fluid>
    <b-form>
      <b-row>
        <b-col cols="2">
          <label for="inline-form-input-title" class="pr-4 text-nowrap">Titre de l'activité: </label>
        </b-col>
        <b-col cols="10">
            <b-form-input
              id="inline-form-input-title"
              size="sm"
              type="text"
              v-model="editableTask.title"
              :class="{ 'is-invalid': $v.editableTask.title.$anyError }"
            />
            <b-form-invalid-feedback>Valeur requise (50 caractères max)</b-form-invalid-feedback>
        </b-col>
      </b-row>

      <b-row class="pt-4">
        <b-col cols="2">
          <label for="inline-form-input-description" class="pr-4 text-nowrap">Description de l'activité : </label>
        </b-col>
        <b-col cols="10">
            <b-form-textarea
              id="inline-form-input-description"
              size="sm"
              type="text"
              rows="6"
              v-model="editableTask.description"
            />
        </b-col>
      </b-row>

      <b-row class="pt-4">
        <b-col cols="2">
          <label for="inline-form-input-description" class="pr-4 text-nowrap">Catégorie de l'activité : </label>
        </b-col>
        <b-col cols="10">
          <multiselect
            v-model="editableTask.taskCategory"
            :multiple="false"
            :close-on-select="true"
            :clear-on-select="true"
            :preserve-search="false"
            :options="taskCategories"
            placeholder="Rechercher/Sélectionner une catégorie de tâche/activité"
            label="title"
            track-by="title"
            :class="{ 'is-invalid': $v.editableTask.taskCategory.$anyError }"
          >
            <span slot="noResult">Pas de résultat correspondant</span>
          </multiselect>
          <b-form-invalid-feedback>Valeur requise</b-form-invalid-feedback>
        </b-col>
      </b-row>

      <div v-if="error" style="color: red;" class="text-center mt-2">
        <p v-for="(error, i) of error.graphQLErrors" :key="i">
          {{ error.message }}
        </p>
      </div>

      <b-button class="d-flex mx-auto mt-4" variant="primary" size="sm" @click.prevent="updateTask">
        Valider
      </b-button>
    </b-form>

  </b-container>
</template>

<script>
import Multiselect from 'vue-multiselect';
import { mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required, maxLength } from 'vuelidate/lib/validators';

export default {
  mixins: [validationMixin],
  data() {
    return {
      error: null,
      editableTask: {
        title: '',
        description: '',
        taskCategory: null,
      },
    };
  },
  components: {
    Multiselect,
  },
  created() {
    if (this.task !== null) {
      this.duplicateTask();
    }
  },
  watch: {
    task: {
      handler() {
        if (this.task !== null) {
          this.duplicateTask();
        }
      },
      immediate: true,
    },
  },
  validations: {
    editableTask: {
      title: {
        required,
        maxLength: maxLength(50),
      },
      taskCategory: {
        required,
      },
    },
  },
  methods: {
    duplicateTask() {
      this.editableTask = { ...this.task };
    },
    async updateTask() {
      this.$v.editableTask.$touch();
      if (this.$v.$anyError) return;
      await this.$store.dispatch('updateTask', {
        title: this.editableTask.title,
        id: this.editableTask.id,
        description: this.editableTask.description,
        taskCategoryId: this.editableTask.taskCategory.id,
      }).then((result) => {
        this.$router.push({
          path: `/ehpads/${this.currentEhpad.id}/tasks`,
        });
      }).catch((error) => {
        this.error = error;
        this.flashMessage.show({
          status: 'error',
          title: error.graphQLErrors[0].extensions.code,
          message: error.graphQLErrors[0].message,
        });
      });
    },
  },
  computed: {
    ...mapGetters({
      currentEhpad: 'ehpad',
      taskCategories: 'taskCategories',
      task: 'task',
    }),
  },
};
</script>

<style lang="scss">

.multiselect__tags {
  border: 1px solid #ced4da !important;
}

</style>
