import gql from 'graphql-tag';

export const CREATE_TASK = gql`
  mutation CREATE_TASK(
    $title: String!
    $description: String
    $ehpadId: ID!
    $taskCategoryId: ID!
  ) {
    createTask(
      input: {
        title: $title
        description: $description
        ehpadId: $ehpadId
        taskCategoryId: $taskCategoryId
      }
    ) {
      task {
        id
        title
        description
        taskCategory {
          id
          title
          description
          icon
        }
      }
    }
  }
`;

export const UPDATE_TASK = gql`
  mutation UPDATE_TASK(
    $id: ID!
    $title: String!
    $taskCategoryId: ID!
    $description: String
  ) {
    updateTask(
      input: {
        id: $id
        title: $title
        description: $description
        taskCategoryId: $taskCategoryId
      }
    ) {
      task {
        id
        title
        description
        taskCategory {
          id
          title
          description
          icon
        }
      }
    }
  }
`;
