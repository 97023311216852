<template>
  <div>
    <div class="d-flex justify-content-center align-items-center mb-3">
      <span class="text-center">
        <h3 class="m-0 mb-3" style="display: inline-block;margin: 0;">
          Modifier une activité/tâche
        </h3>
      </span>
    </div>
    <b-row align-h="center">
      <b-col cols="12" xl="10">
        <app-edit-task-form />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import EditTaskForm from '../../components/tasks/EditTaskForm.vue';

export default {
  components: {
    appEditTaskForm: EditTaskForm,
  },
  props: ['taskId'],
  methods: {
    ...mapActions(['findTask']),
  },
  created() {
    this.findTask(this.taskId);
  },
};

</script>

<style>

</style>
