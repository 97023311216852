<template>
    <div>
        <app-task-categories-table />
    </div>
</template>

<script>
import TaskCategoriesTable from '../../components/task_categories/Table.vue';

export default {
  components: {
    appTaskCategoriesTable: TaskCategoriesTable,
  },
};
</script>

<style lang="scss" scoped>
</style>
