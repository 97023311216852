<template>
  <b-container fluid>
    <b-form>
      <b-row>
        <b-col cols="2">
          <label for="inline-form-input-name" class="pr-4 text-nowrap">Nom du Test : </label>
        </b-col>
        <b-col cols="10">
            <b-form-input
              id="inline-form-input-name"
              size="sm"
              type="text"
              v-model="editableMobilizationTest.name"
              :class="{ 'is-invalid': $v.editableMobilizationTest.name.$anyError }"
            />
            <b-form-invalid-feedback>Valeur requise (100 caractères max)</b-form-invalid-feedback>
        </b-col>
      </b-row>

      <b-row class="pt-4">
        <b-col cols="2">
          <label for="inline-form-input-url" class="pr-4 text-nowrap">Url du Test : </label>
        </b-col>
        <b-col cols="10">
            <b-form-input
              id="inline-form-input-url"
              size="sm"
              type="text"
              v-model="editableMobilizationTest.url"
              :class="{ 'is-invalid': $v.editableMobilizationTest.url.$anyError }"
            />
            <b-form-invalid-feedback>Valeur requise (lien internet)</b-form-invalid-feedback>
        </b-col>
      </b-row>

      <b-row class="pt-4">
        <b-col cols="2">
          <label for="inline-form-input-description" class="pr-4 text-nowrap">Description du Test : </label>
        </b-col>
        <b-col cols="10">
            <b-form-textarea
              id="inline-form-input-description"
              size="sm"
              type="text"
              rows="6"
              v-model="editableMobilizationTest.description"
              :class="{ 'is-invalid': $v.editableMobilizationTest.description.$anyError }"
            />
            <b-form-invalid-feedback>Valeur requise</b-form-invalid-feedback>
        </b-col>
      </b-row>

      <b-row class="pt-4">
        <b-col cols="4">
          <label for="inline-form-input-mobilizationCategories" class="pr-4 text-nowrap">Catégories concernées par le test : </label>
        </b-col>
        <b-col cols="8">
          <multiselect
            v-model="editableMobilizationTest.mobilizationCategories"
            :multiple="true"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="false"
            :options="mobilizationCategoriesOptions"
            placeholder="Selectionner une/plusieurs catégories"
            label="name"
            track-by="name"
            selectLabel=""
            deselectLabel=""
            selectedLabel="Sélectionné"
            :class="{ 'is-invalid': $v.editableMobilizationTest.mobilizationCategories.$anyError }"
          >
            <span slot="noResult">Pas de résultat correspondant</span>

            <template slot="tag" slot-scope="{ option, remove }">
              <span class="multiselect__tag" :style='{backgroundColor: option.color}'>
                <span>{{ option.name }}</span>
                <i aria-hidden="true" tabindex="1" class="multiselect__tag-icon" :style='{backgroundColor: option.color}' @click="remove(option)"></i>
              </span>
            </template>

            </multiselect>
            <b-form-invalid-feedback>Un Test doit s'appliquer à au moins une catégorie</b-form-invalid-feedback>
        </b-col>
      </b-row>

    <span class="d-flex mt-4" v-if="editableMobilizationTest  && editableMobilizationTest.mobilizationCategories.length !== 0">
      <span>Rappel pour chaque catégories (en nombre de jours)</span>
      <i
        class="fas fa-exclamation-triangle mt-1 ml-2"
        style="color: #5bc0de;"
        v-b-tooltip.hover.v-primary
        title="Une alerte s'affichera pour les résidents dont le test est expiré"
      />
    </span>

      <b-row class="pt-4"
        v-for="(mobilizationCategory, index) in editableMobilizationTest.mobilizationCategories"
        v-bind:key="index"
      >
        <b-col cols="4">
          <label>{{mobilizationCategory.name}} :</label>
        </b-col>
        <b-col cols="8">
          <b-form-input
            :id="`${index}`"
            size="sm"
            type="number"
            :number="true"
            v-model="mobilizationCategory.alertTreshold"
            :class="{ 'is-invalid': $v.editableMobilizationTest.mobilizationCategories.$each[index].$anyError }"
          />
        </b-col>
      </b-row>

      <div v-if="error" style="color: red;" class="text-center mt-2">
        <p v-for="(error, i) of error.graphQLErrors" :key="i">
          {{ error.message }}
        </p>
      </div>

      <b-button class="d-flex mx-auto mt-4" variant="primary" size="sm" @click.prevent="updateMobilizationTest">
        Valider
      </b-button>
    </b-form>

  </b-container>
</template>

<script>

import { mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import Multiselect from 'vue-multiselect';
import { required, maxLength, url } from 'vuelidate/lib/validators';

export default {
  mixins: [validationMixin],
  data() {
    return {
      error: null,
      editableMobilizationTest: {
        name: '',
        url: '',
        description: '',
        mobilizationCategories: [],
      },
    };
  },
  created() {
    if (this.mobilizationTest !== null) {
      this.duplicateMobilizationTest(this.mobilizationTest);
    }
  },
  watch: {
    mobilizationTest: {
      handler() {
        if (this.mobilizationTest !== null) {
          this.duplicateMobilizationTest(this.mobilizationTest);
        }
      },
      immediate: true,
    },
  },
  components: {
    Multiselect,
  },
  validations: {
    editableMobilizationTest: {
      name: {
        required,
        maxLength: maxLength(100),
      },
      url: {
        required,
        url,
      },
      description: {
        required,
      },
      mobilizationCategories: {
        required,
        $each: {
          alertTreshold: {
            required,
          },
        },
      },
    },
  },
  methods: {
    duplicateMobilizationTest(mobilizationTest) {
      // Change object
      const formatedMobilizationCategories = [];
      mobilizationTest.mobilizationTestCategories.forEach((mobilizationTestCategory) => {
        formatedMobilizationCategories.push({
          id: mobilizationTestCategory.id,
          mobilizationCategoryId: mobilizationTestCategory.mobilizationCategory.id,
          name: mobilizationTestCategory.mobilizationCategory.name,
          color: mobilizationTestCategory.mobilizationCategory.color,
          alertTreshold: mobilizationTestCategory.alertTreshold,
        });
      });
      this.editableMobilizationTest = {
        id: this.mobilizationTest.id,
        name: this.mobilizationTest.name,
        url: this.mobilizationTest.url,
        description: this.mobilizationTest.description,
        mobilizationCategories: formatedMobilizationCategories,
      };
    },
    async updateMobilizationTest() {
      this.$v.editableMobilizationTest.$touch();
      if (this.$v.$anyError) return;
      await this.$store.dispatch('updateMobilizationTest', {
        name: this.editableMobilizationTest.name,
        id: this.editableMobilizationTest.id,
        url: this.editableMobilizationTest.url,
        description: this.editableMobilizationTest.description,
        mobilizationTestCategories: this.editableMobilizationTest.mobilizationCategories,
      }).then((result) => {
        this.$router.push({
          path: `/ehpads/${this.currentEhpad.id}/mobilization_tests`,
        });
      }).catch((error) => {
        this.error = error;
        this.flashMessage.show({
          status: 'error',
          title: error.graphQLErrors[0].extensions.code,
          message: error.graphQLErrors[0].message,
        });
      });
    },
  },
  computed: {
    ...mapGetters({
      currentEhpad: 'ehpad',
      mobilizationTest: 'mobilizationTest',
    }),
    mobilizationCategoriesOptions() {
      return this.currentEhpad.mobilizationCategories.map((el) => ({
        mobilizationCategoryId: el.id,
        name: el.name,
        color: el.color,
        alertTreshold: 30,
      }));
    },
  },
};
</script>

<style lang="scss">

.multiselect__tags {
  border: 1px solid #ced4da !important;
}

</style>
