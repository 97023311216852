<template>
  <div>

    <div class="d-flex justify-content-center align-items-center mb-3">
      <span class="text-center">
        <h3 class="m-0" style="display: inline-block;margin: 0;">
          Residents sans lit
        </h3>
        <b-button
          class="mb-2 ml-4"
          variant="primary"
          size="sm"
          @click="displayTable"
        >
          <i class="fas fa-plus" />
          Afficher
        </b-button>
      </span>
    </div>

    <b-form-group
      v-if="showTable"
      label-size="sm"
      label-for="filterInput"
      class="mb-3"
      style="width: 40%;"
    >
      <b-input-group size="sm">
        <b-form-input
          v-model="filterNoRoomTable"
          type="search"
          id="filterInput"
          placeholder="Filtre de recherche"
        ></b-form-input>
        <b-input-group-append>
          <b-button :disabled="!filterNoRoomTable" @click="filterNoRoomTable = ''">Effacer</b-button>
        </b-input-group-append>
      </b-input-group>
    </b-form-group>

    <b-table
      v-if="showTable"
      :filter="filterNoRoomTable"
      sticky-header="800px"
      bordered
      striped
      small
      hover
      :items="residentsWithoutRoom"
      :fields="fields"
      class="m-0"
      ref="residentTable"
      id="residentTable"
    >
      <template v-slot:cell(lastMobilizationDiagnostic)="data">
        <span v-html="$options.filters.coloredMobDiagHtml(data.item.lastMobilizationDiagnostic)"></span>
      </template>

     <template v-slot:cell(lastStatus)="data">
        <span v-if="data.item.lastStatus === null">Pas de status</span>
        <span
          v-if="data.item.lastStatus !== null"
          v-html="
            $options.filters.dataWithDate(
              data.item.lastStatus.statusCategory.name,
              data.item.lastStatus.date,
            )
          "
        >
        </span>
      </template>

      <template v-slot:head(actions)>{{ '' }}</template>

      <template v-slot:cell(actions)="data">
        <b-button
          variant="link"
          size="md"
          :to="{ path: `/ehpads/${currentEhpad.id}/resident/${data.item.id}/mobilization` }"
          v-b-tooltip.hover.v-primary.noninteractive
          title="Mobilité"
        >
          <i class="fas fa-chart-line" />
        </b-button>

        <b-button
          variant="link"
          size="md"
          :to="{ path: `/ehpads/${currentEhpad.id}/resident/${data.item.id}/calendar` }"
          v-b-tooltip.hover.v-primary.noninteractive
          title="Programme du résident"
        >
          <i class="far fa-calendar-alt" />
        </b-button>
      </template>
    </b-table>
  </div>
</template>

<script>
// Webpack :
import { mapGetters } from 'vuex';
import _ from 'lodash';
import moment from 'moment';

export default {
  methods: {
    residentElStatus(el) {
      return _.get(el, 'statusCategory.name', null);
    },
    residentElValue(el) {
      return _.get(el, 'value', null);
    },
    residentElMobilizationCategoryName(el) {
      if (el === null) { return 'Pas de catégorie'; }
      return _.get(el.mobilizationCategory, 'name', null);
    },
    selectResident(resident) {
      this.selectedResident = resident;
    },
    displayTable() {
      this.showTable = true;
    },
  },
  computed: {
    ...mapGetters({
      currentEhpad: 'ehpad',
      residentsWithoutRoom: 'residentsWithoutRoom',
    }),
    // currentYear() {
    //   return moment().year();
    // },
    // currentWeek() {
    //   return moment().isoWeek();
    // },
  },
  data() {
    return {
      selectedResident: null,
      filterNoRoomTable: null,
      showTable: false,
      fields: [
        {
          key: 'firstName',
          label: 'Prénom',
          sortable: true,
          tdClass: 'align-middle',
          thClass: 'align-middle',
        },
        {
          key: 'name',
          label: 'Nom',
          sortable: true,
          tdClass: 'align-middle',
          thClass: 'align-middle',
        },
        {
          key: 'height',
          label: 'Taille',
          sortable: true,
          tdClass: 'align-middle',
          thClass: 'align-middle',
          class: 'small-size',
        },
        {
          key: 'lastWeight',
          label: 'Poids (en Kg)',
          sortable: true,
          sortByFormatted: true,
          formatter: 'residentElValue',
          tdClass: 'align-middle',
          thClass: 'align-middle',
        },
        {
          key: 'lastMobilizationDiagnostic',
          label: 'Catégorie',
          formatter: 'residentElMobilizationCategoryName',
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
          tdClass: 'align-middle',
          thClass: 'align-middle',
        },
        {
          key: 'lastStatus',
          label: 'Status',
          sortable: true,
          sortByFormatted: true,
          formatter: 'residentElStatus',
          tdClass: 'align-middle',
          thClass: 'align-middle',
          class: 'small-size',
        },
        {
          key: 'actions',
          label: 'actions',
          class: 'text-center',
          sortable: true,
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
  ::v-deep .small-size {
    width: 75px;
  }
</style>
