import _ from 'lodash';

import apiClient from '../../utilities/api_client';
import { FIND_TASK_CATEGORY, FETCH_TASK_CATEGORIES } from '../../graphql/task_categories/queries';
import {
  CREATE_TASK_CATEGORY,
  UPDATE_TASK_CATEGORY,
} from '../../graphql/task_categories/mutations';

const taskCategoryState = {
  taskCategory: null,
  taskCategories: [],
};

const mutations = {
  SET_TASK_CATEGORIES(state, taskCategories) {
    state.taskCategories = taskCategories;
  },
  SELECT_TASK_CATEGORY(state, taskCategory) {
    state.taskCategory = taskCategory;
  },
  ADD_TASK_CATEGORY(state, taskCategory) {
    state.taskCategories.push(taskCategory);
  },
  UPDATE_TASK_CATEGORY(state, taskCategory) {
    const index = _.findIndex(state.taskCategories, { id: taskCategory.id });
    state.taskCategories.splice(index, 1, taskCategory);
  },
};

const actions = {
  setTaskCategories: async ({ commit, state }, ehpadId) => {
    state.taskCategories = [];
    const { data } = await apiClient.query({
      query: FETCH_TASK_CATEGORIES,
      variables: { ehpadId },
      fetchPolicy: 'network-only',
    });
    commit('SET_TASK_CATEGORIES', data.taskCategories);
  },
  findTaskCategory: async ({ commit, state }, id) => {
    state.taskCategory = null;
    const { data } = await apiClient.query({
      query: FIND_TASK_CATEGORY,
      variables: { id },
      fetchPolicy: 'network-only',
    });
    commit('SELECT_TASK_CATEGORY', data.findTaskCategory);
  },
  createTaskCategory: async ({ commit }, formData) => {
    const { data } = await apiClient.mutate({
      mutation: CREATE_TASK_CATEGORY,
      variables: { ...formData },
    });
    const { taskCategory } = data.createTaskCategory;
    commit('ADD_TASK_CATEGORY', taskCategory);
  },
  updateTaskCategory: async ({ commit }, formData) => {
    const { data } = await apiClient.mutate({
      mutation: UPDATE_TASK_CATEGORY,
      variables: { ...formData },
    });
    const { taskCategory } = data.updateTaskCategory;
    commit('UPDATE_TASK_CATEGORY', taskCategory);
  },
};

const getters = {
  taskCategories: (state) => state.taskCategories,
  taskCategory: (state) => state.taskCategory,
};

export default {
  state: taskCategoryState,
  mutations,
  actions,
  getters,
};
