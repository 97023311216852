<template>
  <div>
    <b-row class="mb-2 justify-content-between">
      <b-col cols="8">
        <span>Récurrence sélectionnée :
          <b>
            {{ selectedCustomRRuleOption.text }}
          </b>
        </span>
      </b-col>
      <b-col cols="4" v-if="!disableEdit">
        <b-button style="float: right;" variant="primary" size="sm" @click="toggleRecurForm()">
          Options de récurrence
        </b-button>
      </b-col>
    </b-row>
    <div v-if="displayRecurForm">
      <hr />
      <b-row class="mb-2">
        <b-col cols="3">
          <label for="customFreq" class="text-nowrap">Récurrence pré-configurée : </label>
        </b-col>
        <b-col cols="9">
            <b-form-select
              id="customFreq"
              v-model="selectedCustomRRuleOption.value"
              :options="customRRuleOptions"
              size="sm"
              :disabled='disableEdit'
            >
          </b-form-select>
        </b-col>
      </b-row>
      <b-row v-if="this.editableEvent.rrule !== null">
        <b-col cols="3">
          <span>Début :
            <b>
              Le {{`${$options.filters.dateFormatterShort(dtstart())}`}}
            </b>
          </span>
        </b-col>
        <b-col cols="4">
          <label for="inline-form-input-title" class="pr-4 text-nowrap">Fin (6 mois par défaut): </label>
        </b-col>
        <b-col cols="5">
          <b-form-datepicker
            id="date-datepicker-status"
            :disabled='disableEdit'
            label-help
            label-no-date-selected="Aucune date sélectionnée"
            size="sm"
            v-model="rruleUntil"
            locale="fr"
          />
        </b-col>
      </b-row>
      <hr />

    </div>
  </div>
</template>
<script>
import moment from 'moment';
import _ from 'lodash';
import { validationMixin } from 'vuelidate';

export default {
  props: {
    editableEvent: {
      type: Object,
      required: true,
    },
    disableEdit: {
      type: Boolean,
      required: true,
    },
  },
  mixins: [validationMixin],
  data() {
    return {
      displayRecurForm: false,
      rruleUntil: null,
      eventDate: {
        day: null,
        dayNumber: null,
        month: null,
        pos: null,
        rruleDay: null,
      },
      selectedCustomRRuleOption: null,
    };
  },
  created() {
    moment.locale('fr-FR');
    this.destructEditableEventDateForCustomFreq();
    if (this.editableEvent.rrule !== null) {
      this.toggleRecurForm();
      this.rruleUntil = this.editableEvent.rrule.until ? moment(this.editableEvent.rrule.until).toDate() : this.in6Months();
      this.selectedCustomRRuleOption = {
        ...this.customRRuleOptions.find(function (elem) {
          return (elem.value.freq === this.editableEvent.rrule.freq)
          && _.isEqual(this.editableEvent.rrule.byweekday, elem.value.byweekday)
          && _.isEqual(this.editableEvent.rrule.bysetpos, elem.value.bysetpos);
        }, this),
      };
    } else {
      this.selectedCustomRRuleOption = { ...this.customRRuleOptions[0] };
    }
  },
  watch: {
    'selectedCustomRRuleOption.value': {
      handler() {
        this.selectedCustomRRuleOption.text = this.customRRuleOptions.find(function (elem) {
          return (this.selectedCustomRRuleOption.value === elem.value);
        }, this).text;
        if (this.editableEvent.rrule === null) { this.editableEvent.rrule = {}; }
        this.editableEvent.rrule.byweekday = this.selectedCustomRRuleOption.value.byweekday;
        this.editableEvent.rrule.freq = this.selectedCustomRRuleOption.value.freq;
        this.editableEvent.rrule.bysetpos = this.selectedCustomRRuleOption.value.bysetpos;
        this.rruleUntil = this.editableEvent.rrule.until ? moment(this.editableEvent.rrule.until).toDate() : this.in6Months();
        this.editableEvent.rrule.dtstart = this.dtstart();
      },
    },
    'editableEvent.rrule.freq': {
      handler() {
        if (this.editableEvent.rrule?.freq === null) {
          this.editableEvent.rrule = null;
        }
      },
    },
    rruleUntil: {
      handler() {
        if (this.editableEvent.rrule !== null) {
          this.editableEvent.rrule.until = moment(this.rruleUntil).toDate();
        }
      },
    },
    'editableEvent.start': {
      handler() {
        this.destructEditableEventDateForCustomFreq();
        this.editableEvent.rrule.dtstart = this.dtstart();
      },
    },
    'editableEvent.end': {
      handler() {
        this.destructEditableEventDateForCustomFreq();
      },
    },
  },
  methods: {
    toggleRecurForm() {
      this.displayRecurForm = !this.displayRecurForm;
    },
    in6Months() {
      return moment(this.editableEvent.start).add(6, 'M').toDate();
    },
    eventStart() {
      return moment(this.editableEvent.start).toDate();
    },
    getWeekIndexInMonth() {
      return Math.ceil(parseInt(this.eventDate.dayNumber, 10) / 7);
    },
    destructEditableEventDateForCustomFreq() {
      this.eventDate.day = moment(this.editableEvent.start).format('dddd');
      this.eventDate.rruleDay = [moment(this.editableEvent.start).locale('en').format('dddd').slice(0, 2).toUpperCase()];
      this.eventDate.dayNumber = moment(this.editableEvent.start).format('D');
      this.eventDate.month = moment(this.editableEvent.start).format('MMMM');
      this.eventDate.pos = this.getWeekIndexInMonth();
    },
    dtstart() {
      return this.editableEvent.rrule.dtstart
        ? moment(this.editableEvent.rrule.dtstart).toDate()
        : moment(this.editableEvent.start).toDate();
    },
  },
  computed: {
    customRRuleOptions() {
      return [
        { text: 'Aucune', value: { freq: null, byweekday: null, bysetpos: null } },
        { text: 'Tous les jours', value: { freq: 'DAILY', byweekday: null, bysetpos: null } },
        { text: `Toutes les semaines, le ${this.eventDate.day}`, value: { freq: 'WEEKLY', byweekday: null, bysetpos: null } },
        { text: `Tous les mois, le ${this.eventDate.dayNumber}`, value: { freq: 'MONTHLY', byweekday: null, bysetpos: null } },
        { text: `Tous les mois, le ${this.humanPos} ${this.eventDate.day}`, value: this.monthlyCustomFreq },
        { text: `Tous les ans, le ${this.eventDate.dayNumber} ${this.eventDate.month}`, value: { freq: 'YEARLY', byweekday: null, bysetpos: null } },
        { text: 'Tous les jours (Du lundi au vendredi)', value: { freq: 'WEEKLY', byweekday: ['MO', 'TU', 'WE', 'TH', 'FR'], bysetpos: null } },
      ];
    },
    monthlyCustomFreq() {
      return {
        freq: 'MONTHLY',
        byweekday: [`${this.eventDate.rruleDay}`],
        bysetpos: `${this.eventDate.pos}`,
      };
    },
    humanPos() {
      if (this.eventDate.pos === 1) { return `${this.eventDate.pos}er`; }
      return `${this.eventDate.pos}ème`;
    },
  },
};
</script>
<style lang="scss" scoped>

</style>
