<template>
  <div>
    <h3 class="text-center mb-3">
      Residents
    </h3>

    <b-form-group
      label-size="sm"
      label-for="filterInput"
      class="mb-3"
      style="width: 40%;"
    >
      <b-input-group size="sm">
        <b-form-input
          v-model="filter"
          type="search"
          id="filterInput"
          placeholder="Filtre de recherche"
        ></b-form-input>
        <b-input-group-append>
          <b-button :disabled="!filter" @click="filter = ''">Effacer</b-button>
        </b-input-group-append>
      </b-input-group>
    </b-form-group>

    <b-table
      v-model="residentsWithRoomSorted"
      :filter="filter"
      sticky-header="800px"
      bordered
      striped
      small
      hover
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :items="residentsWithRoom"
      :fields="computedFields"
      class="m-0"
      ref="residentTable"
      id="residentTable"
    >
      <template v-slot:cell(lastMobilizationDiagnostic)="data">
        <span v-html="$options.filters.coloredMobDiagHtml(data.item.lastMobilizationDiagnostic)"></span>
        <i
          v-if="data.item.lastMobilizationDiagnostic !== null && data.item.lastMobilizationDiagnostic !== undefined"
          class="fas fa-exclamation-triangle mt-1 ml-2"
          style="color: #5bc0de;"
          v-b-tooltip.hover.v-primary
          :title=data.item.lastMobilizationDiagnostic.mobilizationCategory.description
        />
      </template>

      <template v-slot:cell(expiredMobilizationTests)="data">
      </template>

     <template v-slot:cell(lastStatus)="data">
        <span v-if="data.item.lastStatus === null">Pas de status</span>
        <span
          v-if="data.item.lastStatus !== null"
          v-html="
            $options.filters.dataWithDate(
              data.item.lastStatus.statusCategory.name,
              data.item.lastStatus.date,
            )
          "
        >
        </span>
      </template>

      <template v-slot:cell(room)="data">
        <span v-if="data.item.lastStatus.statusCategory.name == 'Entrée'"><i class="fas fa-bed"></i></span>
        <span>
          {{ (data.item.room && `N° ${data.item.room.number}`) || 'Aucun lit assigné' }}
        </span>
      </template>

      <template v-slot:cell(sector)="data">
        <span style="font-size: medium;">{{ (data.item.sector && `${data.item.sector.name}`) || 'Pas de secteur' }}</span>
      </template>

      <template v-slot:head(actions)>{{ '' }}</template>

      <template v-slot:cell(actions)="data">
        <b-button
          variant="link"
          size="md"
          :to="{ path: `/ehpads/${currentEhpad.id}/resident/${data.item.id}/mobilization` }"
          v-b-tooltip.hover.v-primary.noninteractive
          title="Mobilité"
        >
          <i class="fas fa-chart-line" />
        </b-button>

        <b-button
          variant="link"
          size="md"
          :to="{ path: `/ehpads/${currentEhpad.id}/resident/${data.item.id}/calendar` }"
          v-b-tooltip.hover.v-primary.noninteractive
          title="Programme du résident"
        >
          <i class="far fa-calendar-alt" />
        </b-button>
      </template>

      <template slot="top-row" slot-scope="data">
        <td class="align-middle text-center" v-for="field in data.fields" :key="field.key">
          <b-button
            variant="primary"
            size="sm"
            block
            @click="createResident"
            v-if="field.key === 'actions'"
          >
            <i class="fas fa-plus" />
            Ajouter
          </b-button>

          <b-form-select
            size="sm"
            class="small-size rooms-list-select"
            v-else-if="field.key === 'room'"
            v-model="newResident[field.key]"
            :state="$v.newResident[field.key] && !$v.newResident[field.key].$error"
          >
            <!-- Premier élément de la liste -->
            <template v-slot:first>
              <b-form-select-option :value="null" disabled>{{
                roomsSelectPrompt
              }}</b-form-select-option>
            </template>
            <b-form-select-option
              v-for="room in rooms"
              :value="room.id"
              :key="room.id"
              :disabled="!room.isFree"
              >{{ `N° ${room.number}` }}</b-form-select-option
            >
            <b-form-invalid-feedback>{{ `${field.label} requis` }}</b-form-invalid-feedback>
          </b-form-select>

          <b-form-group
            v-else-if="field.key !== 'sector' &&
              field.key !== 'lastStatus' &&
              field.key !== 'lastMobilizationDiagnostic' &&
              field.key !== 'expiredMobilizationTests'
            "
            class="m-0"
            :class="classFor(field.key)"
            :invalid-feedback="`${field.label} requis`"
            :state="$v.newResident[field.key] && !$v.newResident[field.key].$error"
          >
            <b-form-input
              size="sm"
              v-model="newResident[field.key]"
              :class="{
                'is-invalid': $v.newResident[field.key] && $v.newResident[field.key].$error,
              }"
              type="text"
              min="0"
              :placeholder="field.label"
            />
          </b-form-group>
        </td>
      </template>
    </b-table>
    <hr />
  </div>
</template>

<script>
// Webpack :
import { mapGetters, mapActions } from 'vuex';
import _ from 'lodash';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';

export default {
  mixins: [validationMixin],
  data() {
    return {
      residentsWithRoomSorted: [],
      selectedResident: null,
      filter: null,
      filterNoRoom: null,
      sortBy: 'name',
      sortDesc: false,
      newResident: {
        firstName: '',
        name: '',
        room: null,
      },
      fields: [
        {
          key: 'firstName',
          label: 'Prénom',
          sortable: true,
          tdClass: 'align-middle',
          thClass: 'align-middle',
        },
        {
          key: 'name',
          label: 'Nom',
          sortable: true,
          tdClass: 'align-middle',
          thClass: 'align-middle',
        },
        {
          key: 'height',
          label: 'Taille',
          sortable: true,
          tdClass: 'align-middle',
          thClass: 'align-middle',
          class: 'small-size',
        },
        {
          key: 'lastWeight',
          label: 'Poids (en Kg)',
          sortable: true,
          sortByFormatted: true,
          formatter: 'residentElValue',
          tdClass: 'align-middle',
          thClass: 'align-middle',
        },
        {
          key: 'lastMobilizationDiagnostic',
          label: 'Catégorie',
          formatter: 'residentElMobilizationCategoryName',
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
          tdClass: 'align-middle',
          thClass: 'align-middle',
        },
        {
          key: 'lastStatus',
          label: 'Status',
          sortable: true,
          sortByFormatted: true,
          formatter: 'residentElStatus',
          tdClass: 'align-middle',
          thClass: 'align-middle',
        },
        {
          key: 'room',
          label: 'Lit',
          sortable: true,
          sortByFormatted: true,
          formatter: 'residentElNumber',
          tdClass: 'align-middle',
          thClass: 'align-middle',
        },
        {
          key: 'sector',
          label: 'Secteur',
          sortable: true,
          tdClass: 'align-middle',
          thClass: 'align-middle',
        },
        {
          key: 'expiredMobilizationTests',
          label: 'Tests',
          sortable: true,
          tdClass: (value) => {
            if (value && value.length > 0) {
              return 'background-red small-size';
            }
            return 'background-green small-size';
          },
          thClass: 'align-middle',
          sortByFormatted: true,
          filterByFormatted: true,
          formatter: 'expiredTestBoolean',
        },
        {
          key: 'actions',
          label: 'actions',
          class: 'text-center',
          sortable: true,
        },
      ],
    };
  },
  validations: {
    newResident: {
      name: {
        required,
      },
      firstName: {
        required,
      },
      height: {
        required,
      },
      lastWeight: {
        required,
      },
    },
  },
  created() {
    this.sortBy = this.$cookies.get('mobiviewSortingMethodResidentWithRoomTable');
    const boolean = (this.$cookies.get('mobiviewSortingWayResidentWithRoomTable') === 'true' || this.$cookies.get('mobiviewSortingWayResidentWithRoomTable') === true);
    this.sortDesc = boolean;
  },
  watch: {
    sortBy(newVal) {
      this.$cookies.set('mobiviewSortingMethodResidentWithRoomTable', newVal);
    },
    sortDesc(newVal) {
      this.$cookies.set('mobiviewSortingWayResidentWithRoomTable', newVal);
    },
    residentsWithRoomSorted(newVal) {
      localStorage.mobiviewResidentsWithRoomSorted = JSON.stringify(newVal);
    },
  },
  methods: {
    ...mapActions(['setRooms']),
    resetResidentForm() {
      this.newResident = {
        firstName: '',
        name: '',
        height: null,
        lastWeight: null,
        room: null,
      };
    },
    residentElStatus(el) {
      return _.get(el, 'statusCategory.name', null);
    },
    residentElValue(el) {
      return _.get(el, 'value', null);
    },
    async createResident() {
      // v.touch pour jouer les validations
      this.$v.$touch();
      if (this.$v.$anyError) return;

      await this.$store.dispatch('createResident', {
        name: this.newResident.name,
        firstName: this.newResident.firstName,
        height: parseInt(this.newResident.height, 10),
        weight: parseFloat(this.newResident.lastWeight),
        roomId: parseInt(this.newResident.room, 10),
        ehpadId: this.currentEhpad.id,
      }).then((result) => {
        // Reset des valdiations vuelidate
        this.$v.$reset();
        this.resetResidentForm();
      }).catch((error) => {
        this.flashMessage.show({
          status: 'error',
          title: error.graphQLErrors[0].extensions.code,
          message: error.graphQLErrors[0].message,
        });
      });
      await this.setRooms(this.currentEhpad.id);
    },
    residentElNumber(el) {
      return _.get(el, 'number', null);
    },
    residentElMobilizationCategoryName(el) {
      if (el === null) { return 'Pas de catégorie'; }
      return _.get(el.mobilizationCategory, 'name', null);
    },
    classFor(key) {
      if (key === 'height') { return 'small-size'; }
    },
    expiredTestBoolean(el) {
      if (el && el.length > 0) {
        return true;
      }
      return false;
    },
  },
  computed: {
    ...mapGetters({
      currentEhpad: 'ehpad',
      rooms: 'rooms',
      residentsWithRoom: 'residentsWithRoom',
    }),
    computedFields() {
      if (!this.currentEhpad.allSettings.configuration.sectors.activate_sectors) {
        return this.fields.filter((field) => field.key !== 'sector');
      }
      return this.fields;
    },
    roomsSelectPrompt() {
      return _.find(this.rooms, { isFree: true }) ? 'Choisir un lit' : 'Aucun lit disponible';
    },
    // currentYear() {
    //   return moment().year();
    // },
    // currentWeek() {
    //   return moment().isoWeek();
    // },
  },
};
</script>

<style lang="scss" scoped>
  ::v-deep .small-size {
    width: 75px;
  }
  ::v-deep .background-red {
    background-color: #dc3545;
  }
  ::v-deep .background-green {
    background-color: #28a745;
  }
  .rooms-list-select option {
    color: black;
    font-weight: bold;
  }
  .rooms-list-select option:disabled {
    color: rgb(170, 170, 170) !important;
    font-weight: normal !important;
  }
</style>
