<template>
  <!-- https://bootstrap-vue.js.org/docs/components/table -->
  <div>
    <div class="d-flex justify-content-center align-items-center mb-3">
      <span class="text-center">
        <h3 class="m-0" style="display: inline-block;margin: 0;">
          Activités/Tâches
        </h3>
        <b-button class="mb-2 ml-4" variant="primary" size="sm"
          :to="{path: `/ehpads/${currentEhpad.id}/tasks/new`}"
        >
          <i class="fas fa-plus" />
          Créer une nouvelle Activité/Tâche
        </b-button>
        <b-button class="mb-2 ml-4" variant="primary" size="sm"
          :to="{path: `/ehpads/${currentEhpad.id}/task_categories`}"
        >
          <i class="fas fa-dumbbell" />
          Gérer les catégories des Activité/Tâche
        </b-button>
      </span>
    </div>

    <b-form-group
      label-size="sm"
      label-for="filterInput"
      class="mb-3"
      style="width: 40%;"
    >
      <b-input-group size="sm">
        <b-form-input
          v-model="filter"
          type="search"
          id="filterInput"
          placeholder="Filtre de recherche"
        ></b-form-input>
        <b-input-group-append>
          <b-button :disabled="!filter" @click="filter = ''">Effacer</b-button>
        </b-input-group-append>
      </b-input-group>
    </b-form-group>

    <b-table
      :filter="filter"
      bordered
      striped
      small
      hover
      :items="tasks"
      :fields="fields"
      :sort-by.sync="sortBy"
      class="m-0"
      ref="tasksTable"
      id="tasksTable"
    >

      <template v-slot:head(actions)>{{ '' }}</template>

      <template v-slot:cell(actions)="data">
        <b-button
          variant="link"
          size="sm"
          :to="{path: `/ehpads/${currentEhpad.id}/tasks/${data.item.id}`}"
        >
          <i class="fas fa-edit" />
          Modifier/Voir
        </b-button>
      </template>
    </b-table>

  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  data() {
    return {
      filter: null,
      sortBy: 'title',
      fields: [
        {
          key: 'title',
          label: 'Titre',
          sortable: true,
          tdClass: 'align-middle',
          thClass: 'align-middle',
        },
        {
          key: 'description',
          label: 'Description',
          sortable: true,
          tdClass: 'align-middle',
          thClass: 'align-middle',
        },
        {
          key: 'taskCategory.title',
          label: 'Catégorie',
          sortable: true,
          tdClass: 'align-middle',
          thClass: 'align-middle',
        },
        {
          key: 'actions',
          label: 'actions',
          class: 'text-center',
          tdClass: 'align-middle',
          thClass: 'align-middle',
        },
      ],
    };
  },
  created() {
    this.setTasks(this.currentEhpad.id);
    this.setTaskCategories(this.currentEhpad.id);
  },
  methods: {
    ...mapActions(['setTasks', 'setTaskCategories']),
  },
  computed: {
    ...mapGetters({
      tasks: 'tasks',
      currentEhpad: 'ehpad',
    }),
  },
};
</script>

<style lang="scss" scoped>
  ::v-deep .small-size {
    max-width: 75px;
  }

</style>
