<template>
  <div>
    <div class="d-flex justify-content-center align-items-center mb-3">
      <span class="text-center">
        <h3 class="m-0" style="display: inline-block;margin: 0;">
          Nouvelle Catégorie d'Activité/Tâche
        </h3>
      </span>
    </div>
    <b-row align-h="center">
      <b-col cols="12" xl="10">
        <app-new-task-category-form />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import NewTaskCategoryForm from '../../components/task_categories/NewTaskCategoryForm.vue';

export default {
  components: {
    appNewTaskCategoryForm: NewTaskCategoryForm,
  },
};

</script>

<style>

</style>
