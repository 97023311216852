import gql from 'graphql-tag';

export const FETCH_TASK_CATEGORIES = gql`
  query FETCH_TASK_CATEGORIES($ehpadId: ID!) {
    taskCategories(ehpadId: $ehpadId) {
      id
      title
      description
      icon
    }
  }
`;

export const FIND_TASK_CATEGORY = gql`
  query FIND_TASK_CATEGORY($id: ID!) {
    findTaskCategory(id: $id) {
      id
      title
      description
      icon
    }
  }
`;
