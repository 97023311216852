import gql from 'graphql-tag';

export const FETCH_RESIDENTS = gql`
  query FETCH_RESIDENTS($ehpadId: ID!) {
    residents(ehpadId: $ehpadId) {
      id
      name
      firstName
      height
      lastWeight {
        id
        date
        value
      }
      lastStatus {
        id
        description
        date
        statusCategory {
          name
        }
      }
      expiredMobilizationTests {
        id
        name
      }
      lastMobilizationDiagnostic {
        id
        mobilizationCategory {
          id
          name
          number
          color
          description
        }
      }
      room {
        id
        number
      }
      sector {
        id
        name
      }
    }
  }
`;

export const FIND_RESIDENT = gql`
  query FIND_RESIDENT(
      $id: ID!
    ) {
    findResident(
      id: $id
    ) {
      id
      name
      firstName
      height
      lastWeight {
        id
        date
        value
      }
      lastStatus {
        id
        description
        date
        statusCategory {
          name
        }
      }
      expiredMobilizationTests {
        id
        name
      }
      lastMobilizationDiagnostic {
        id
        mobilizationCategory {
          id
          name
          number
          color
          description
        }
      }
      mobilizationDiagnostics {
        id
        value
        date
        user {
          id
          email
        }
        comment
        mobilizationCategory {
          id
          name
          number
          color
        }
      }
      residentTests {
        id
        date
        result
        comment
        mobilizationTest {
          id
          name
          mobilizationTestCategories {
            id
            alertTreshold
          }
        }
      }
      room {
        id
        number
      }
      sector {
        id
        name
      }
    }
  }
`;
