<template>
  <div>
    <div class="d-flex justify-content-center align-items-center mb-3">
      <span class="text-center">
        <h3 class="m-0 mb-3" style="display: inline-block;margin: 0;">
          Modifier une catégorie d'activité/tâche
        </h3>
      </span>
    </div>
    <b-row align-h="center">
      <b-col cols="12" xl="10">
        <app-edit-task-category-form />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import EditTaskCategoryForm from '../../components/task_categories/EditTaskCategoryForm.vue';

export default {
  components: {
    appEditTaskCategoryForm: EditTaskCategoryForm,
  },
  props: ['taskCategoryId'],
  methods: {
    ...mapActions(['findTaskCategory']),
  },
  created() {
    this.findTaskCategory(this.taskCategoryId);
  },
};

</script>

<style>

</style>
