import Home from './pages/Home.vue';
import Signin from './pages/Signin.vue';
import EhpadShow from './pages/ehpads/Show.vue';
import EhpadMainMenu from './pages/ehpads/MainMenu.vue';
import EhpadSetup from './pages/ehpads/Setup.vue';
import EhpadInfos from './pages/ehpads/Infos.vue';
import EhpadsIndex from './pages/ehpads/Index.vue';
import EhpadDashboard from './pages/ehpads/Dashboard.vue';
import EhpadCalendar from './pages/ehpads/Calendar.vue';
import MobilizationTestsIndex from './pages/mobilization_tests/Index.vue';
import TasksIndex from './pages/tasks/Index.vue';
import TaskNew from './pages/tasks/New.vue';
import TaskShow from './pages/tasks/Show.vue';
import TaskCategoriesIndex from './pages/task_categories/Index.vue';
import TaskCategoryNew from './pages/task_categories/New.vue';
import TaskCategoryShow from './pages/task_categories/Show.vue';
import MobilizationTestNew from './pages/mobilization_tests/New.vue';
import MobilizationTestShow from './pages/mobilization_tests/Show.vue';
import UsersIndex from './pages/users/Index.vue';
import UserShow from './pages/users/Show.vue';
import ResidentsIndex from './pages/residents/Index.vue';
import ResourcesIndex from './pages/resources/Index.vue';
import ResourcesNew from './pages/resources/New.vue';
import ResourcesShow from './pages/resources/Show.vue';
import ResourceCalendar from './pages/resources/Calendar.vue';
import ResidentCalendar from './pages/residents/Calendar.vue';
import ResidentMobilization from './pages/residents/Mobilization.vue';

const routes = [
  {
    path: '/signin',
    component: Signin,
    name: 'SignIn',
    exact: true,
  },
  {
    path: '/ehpads',
    component: EhpadsIndex,
    exact: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/users',
    component: UsersIndex,
    name: 'UsersIndex',
    exact: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/profile',
    component: UserShow,
    name: 'UserShow',
    exact: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/ehpads/:ehpadId',
    component: EhpadShow,
    exact: true,
    props: true,
    // Meta : logique dans application.js pour vérifier l'authentification avant le routage
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: 'main',
        component: EhpadMainMenu,
        name: 'EhpadMain',
      },
      {
        path: 'infos',
        component: EhpadInfos,
        name: 'EhpadInfos',
      },
      {
        path: 'setup',
        component: EhpadSetup,
        name: 'EhpadSetup',
      },
      {
        path: 'dashboard',
        component: EhpadDashboard,
      },
      {
        path: 'mobilization_tests',
        component: MobilizationTestsIndex,
        name: 'MobilizationTestsIndex',
      },
      {
        path: 'mobilization_tests/new',
        component: MobilizationTestNew,
        props: true,
      },
      {
        path: 'mobilization_tests/:mobilizationTestId',
        component: MobilizationTestShow,
        props: true,
      },
      {
        path: 'tasks',
        component: TasksIndex,
        name: 'TasksIndex',
      },
      {
        path: 'tasks/new',
        component: TaskNew,
        props: true,
      },
      {
        path: 'tasks/:taskId',
        component: TaskShow,
        props: true,
      },
      {
        path: 'task_categories',
        component: TaskCategoriesIndex,
        name: 'TaskCategoriesIndex',
      },
      {
        path: 'task_categories/new',
        component: TaskCategoryNew,
        props: true,
      },
      {
        path: 'task_categories/:taskCategoryId',
        component: TaskCategoryShow,
        props: true,
      },
      {
        path: 'calendar',
        component: EhpadCalendar,
        name: 'EhpadCalendar',
        props: true,
      },
      {
        path: 'residents',
        component: ResidentsIndex,
        name: 'ResidentsIndex',
      },
      {
        path: 'resident/:residentId/mobilization',
        component: ResidentMobilization,
        props: true,
      },
      {
        path: 'resident/:residentId/calendar',
        component: ResidentCalendar,
        name: 'ResidentCalendar',
        props: true,
      },
      {
        path: 'resources',
        component: ResourcesIndex,
        name: 'ResourcesIndex',
      },
      {
        path: 'resources/new',
        component: ResourcesNew,
        props: true,
      },
      {
        path: 'resources/:resourceId',
        component: ResourcesShow,
        props: true,
      },
      {
        path: 'resource/:resourceId/calendar',
        component: ResourceCalendar,
        name: 'ResourceCalendar',
        props: true,
      },
    ],
  },
  {
    path: '/',
    component: Home,
    meta: {
      requiresAuth: true,
    },
  },
  { path: '*', redirect: '/' },
];

export default routes;
