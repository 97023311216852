<template>
  <b-container fluid :style="ehpadColor">
    <b-row class="justify-content-center my-5">
      <b-col cols="6" lg="2">
        <div class="menu-link text-center">
          <router-link
            :to="{path: `/ehpads/${ehpad.id}/mobilization_tests`}"
            class="custom-icon"
            style="cursor: pointer"
          >
            <img src="/assets/images/tests-off.png" class="img-back">
            <img src="/assets/images/tests-on.png" class="img-top">
          </router-link>
        </div>
        <p class="text-center mt-2">Tests</p>
      </b-col>
      <b-col cols="6" lg="2">
        <div class="menu-link text-center">
          <router-link
            :to="{path: `/ehpads/${ehpad.id}/residents`}"
            class="custom-icon"
            style="cursor: pointer"
          >
            <img src="/assets/images/residents-off.png" class="img-back">
            <img src="/assets/images/residents-on.png" class="img-top">
          </router-link>
        </div>
        <p class="text-center mt-2">Résidents</p>
      </b-col>
      <b-col cols="6" lg="2">
        <div class="menu-link text-center">
          <router-link
            :to="{path: `/ehpads/${ehpad.id}/resources`}"
            class="custom-icon"
            style="cursor: pointer"
          >
            <img src="/assets/images/ressources-off.png" class="img-back">
            <img src="/assets/images/ressources-on.png" class="img-top">
          </router-link>
        </div>
        <p class="text-center mt-2">Ressources</p>
      </b-col>
      <b-col cols="6" lg="2">
        <div class="menu-link text-center">
          <router-link
            :to="{path: `/ehpads/${ehpad.id}/tasks`}"
            class="custom-icon"
            style="cursor: pointer"
          >
            <img src="/assets/images/activities-off.png" class="img-back">
            <img src="/assets/images/activities-on.png" class="img-top">
          </router-link>
        </div>
        <p class="text-center mt-2">Tâches/Activités</p>
      </b-col>
    </b-row>
    <b-row class="justify-content-center my-5">
      <b-col cols="6" lg="2">
        <div class="menu-link text-center">
          <router-link
            :to="{path: `/ehpads/${ehpad.id}/calendar`}"
            class="custom-icon"
            style="cursor: pointer"
          >
            <img src="/assets/images/programmes-off.png" class="img-back">
            <img src="/assets/images/programmes-on.png" class="img-top">
          </router-link>
        </div>
        <p class="text-center mt-2">Programmes</p>
      </b-col>
      <b-col  cols="6" lg="2">
        <div class="menu-link text-center">
          <router-link
            :to="{path: `/ehpads/${ehpad.id}/dashboard/`}"
            class="custom-icon"
            style="cursor: pointer"
          >
            <img src="/assets/images/tableaudebord-off.png" class="img-back">
            <img src="/assets/images/tableaudebord-on.png" class="img-top">
          </router-link>
        </div>
        <p class="text-center mt-2">Tableau de bord</p>
      </b-col>
      <b-col  cols="6" lg="2">
        <div class="menu-link text-center">
          <router-link
            :to="{path: `/ehpads/${ehpad.id}/infos/`}"
            class="custom-icon"
            style="cursor: pointer"
          >
            <img src="/assets/images/infos-off.png" class="img-back">
            <img src="/assets/images/infos-on.png" class="img-top">
          </router-link>
        </div>
        <p class="text-center mt-2">Informations</p>
      </b-col>
      <b-col  cols="6" lg="2">
        <div class="menu-link text-center">
          <div
            @click="signOut"
            class="custom-icon"
            style="cursor: pointer"
          >
            <img src="/assets/images/connex-off.png" class="img-back">
            <img src="/assets/images/connex-on.png" class="img-top">
          </div>
        </div>
        <p class="text-center mt-2">Déconnexion</p>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';

export default {
  name: 'EhpadMainMenu',
  methods: {
    signOut() {
      this.$store.dispatch('signoutUser');
      this.$router.push('/signin');
    },
  },
  computed: {
    ...mapGetters({
      ehpad: 'ehpad',
    }),
    // currentYear() {
    //   return (moment().year());
    // },
    // currentWeek() {
    //   return (moment().isoWeek());
    // },
    // currentDate() {
    //   return moment().format('DD-MM-YYYY');
    // },
    ehpadColor() {
      return { '--ehpad-color': this.ehpad.color };
    },
  },
};
</script>

<style lang="scss" scoped>

.custom-icon {
  width: 200px;
  border-radius: 9px;
  position: relative;
  display: inline-block;
}

.custom-icon .img-top {
  width: 100%;
  // For black/white
  // display: none;
  display: inline;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
}

.custom-icon .img-back {
  width: 100%;
}

.custom-icon:hover  {
  background-color: var(--ehpad-color);
}
.custom-icon:hover .img-top {
  display: inline;
}

.container-fluid {
  min-height: calc(100vh - 280px);
}

@media only screen and (min-width: 992px) and (max-width: 1300px) {
  .custom-icon {
    width: 150px;
  }
}

@media only screen and (min-width: 1600px) {
  .custom-icon {
    width: 220px;
  }
}

</style>
