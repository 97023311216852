<template>
  <b-modal :title="modalTitle" v-bind="$attrs" static size="lg" class="col-12 text-center">
    <b-row align-h="center" class="mb-3">
      <b-col cols="6">
        <multiselect
          v-model="newTask"
          :multiple="false"
          :close-on-select="true"
          :clear-on-select="true"
          :preserve-search="false"
          :options="tasksOptions"
          placeholder="Rechercher/Sélectionner une tâche/activité"
          label="title"
          track-by="title"
          :class="{ 'is-invalid': $v.newTask.$anyError }"
        >
          <span slot="noResult">Pas de résultat correspondant</span>
        </multiselect>
        <b-form-invalid-feedback>Valeur requise</b-form-invalid-feedback>
      </b-col>
      <b-col cols="2" class="my-auto">
        <b-button
          variant="primary"
          size="sm"
          block
          @click="addTask"
        >
          <i class="fas fa-plus" />
          Ajouter
        </b-button>
      </b-col>
    </b-row>

    <b-row align-h="center">
      <b-col cols="8">
        <draggable
          v-if="editableEvent"
          tag="ul"
          v-model="editableEvent.eventDefaultTasks"
          class="list-group"
          handle=".handle"
          v-bind="dragOptions"
          @start="isDragging = true"
          @end="isDragging = false"
          @change="computeTasksOrder"
        >
          <transition-group type="transition" name="flip-list">
            <div
              v-for="(eventDefaultTask, index) in editableEvent.eventDefaultTasks"
              :key="`${eventDefaultTask}-${index}`"
            >
              <li class="list-group-item">
                <div class="handle">

                  <b-row>
                    <b-col cols="10" class="m-auto" style="font-size:medium;">
                      <i class="fa fa-align-justify"></i>
                      <span class="ml-2">[{{ eventDefaultTask.eventDefaultTaskOrder }}]</span>
                      <span class="ml-2">{{ eventDefaultTask.task.title }}</span>
                    </b-col>

                    <b-col class="align-self-end m-auto">
                      <i class="fa fa-times close" v-if="!disableEdit" @click="removeThisTask(index)"></i>
                    </b-col>
                  </b-row>

                </div>
              </li>
            </div>
          </transition-group>
          </draggable>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';
import draggable from 'vuedraggable';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import Multiselect from 'vue-multiselect';

export default {
  props: ['disableEdit', 'editableEvent'],
  mixins: [validationMixin],
  data() {
    return {
      newTask: null,
      dragging: false,
    };
  },
  components: {
    draggable,
    Multiselect,
  },
  validations: {
    newTask: {
      required,
    },
  },
  created() {
    this.setTasks(this.currentEhpad.id);
  },
  methods: {
    ...mapActions(['setTasks']),
    addTask() {
      this.$v.$touch();
      if (this.$v.$anyError) return;
      const newEventDefaultTask = {
        id: null,
        task: {
          id: this.newTask.id,
          title: this.newTask.title,
          __typename: 'Task',
        },
        eventDefaultTaskOrder: this.editableEvent.eventDefaultTasks.length + 1,
        __typename: 'EventDefaultTask',
      };
      this.editableEvent.eventDefaultTasks.push(newEventDefaultTask);
      this.$v.$reset();
      this.newTask = null;
    },
    removeThisTask(index) {
      this.editableEvent.eventDefaultTasks.splice(index, 1);
      this.computeTasksOrder();
    },
    computeTasksOrder() {
      // let newEventDefaultTasks = this.editableEvent.eventDefaultTasks.map(function(task, index) {
      //     var newTask = Object.assign({}, task);
      //     newTask.eventDefaultTaskOrder = index + 1;
      //     return newTask;
      // });
      // this.editableEvent.eventDefaultTasks = [];
      // this.editableEvent.eventDefaultTasks = newEventDefaultTasks;
      this.editableEvent.eventDefaultTasks.forEach((task, index) => {
        task.eventDefaultTaskOrder = index + 1;
      });
    },
  },
  computed: {
    ...mapGetters({
      currentEhpad: 'ehpad',
      tasks: 'tasks',
    }),
    tasksOptions() {
      const eventDefaultTasksIds = this.editableEvent.eventDefaultTasks.map((eventDefaultTask) => eventDefaultTask.task.id);
      return this.tasks.filter((task) => !eventDefaultTasksIds.includes(task.id));
    },
    dragOptions() {
      return {
        animation: 0,
        group: 'description',
        disabled: this.disableEdit ? true : false,
        ghostClass: 'ghost',
      };
    },
    modalTitle() {
      return `Tâches et Activités pour : ${this.editableEvent.title} du ${moment(this.editableEvent.start).locale('fr').format('DD/MM/YYYY')}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.flip-list-move {
  transition: transform 0.5s;
}
</style>
