import _ from 'lodash';

import apiClient from '../../utilities/api_client';
import { FIND_RESIDENT, FETCH_RESIDENTS } from '../../graphql/residents/queries';
import {
  CREATE_RESIDENT,
  UPDATE_RESIDENT,
} from '../../graphql/residents/mutations';
import { CREATE_RESIDENT_STATUS } from '../../graphql/resident_statuses/mutations';
import { CREATE_MOBILIZATION_DIAGNOSTIC } from '../../graphql/mobilization_diagnostics/mutations';
import { CREATE_RESIDENT_TEST } from '../../graphql/resident_tests/mutations';
import { CREATE_WEIGHT } from '../../graphql/weights/mutations';

const residentState = {
  resident: null,
  residents: [],
};

const mutations = {
  SET_RESIDENTS(state, residents) {
    state.residents = residents;
  },
  SELECT_RESIDENT(state, resident) {
    state.resident = resident;
  },
  ADD_RESIDENT(state, resident) {
    state.residents.push(resident);
  },
  UPDATE_RESIDENT(state, resident) {
    const index = _.findIndex(state.residents, { id: resident.id });
    state.residents.splice(index, 1, resident);
  },
};

const actions = {
  setResidents: async ({ commit, state }, ehpadId) => {
    state.residents = [];
    const { data } = await apiClient.query({
      query: FETCH_RESIDENTS,
      variables: { ehpadId },
      fetchPolicy: 'network-only',
    });
    commit('SET_RESIDENTS', data.residents);
  },
  findResident: async ({ commit, state }, formData) => {
    state.resident = null;
    const { data } = await apiClient.query({
      query: FIND_RESIDENT,
      variables: { ...formData },
      fetchPolicy: 'network-only',
    });
    commit('SELECT_RESIDENT', data.findResident);
  },
  createResident: async ({ commit }, formData) => {
    const { data } = await apiClient.mutate({
      mutation: CREATE_RESIDENT,
      variables: { ...formData },
    });
    commit('ADD_RESIDENT', data.createResident.resident);
  },
  updateResident: async ({ commit, dispatch }, formData) => {
    const { data } = await apiClient.mutate({
      mutation: UPDATE_RESIDENT,
      variables: { ...formData },
    });
    const { resident } = data.updateResident;
    commit('UPDATE_RESIDENT', resident);
    dispatch('findResident', { id: formData.id });
    return resident;
  },
  createResidentStatus: async ({ commit, dispatch }, formData) => {
    await apiClient.mutate({
      mutation: CREATE_RESIDENT_STATUS,
      variables: { ...formData },
    });
    dispatch('findResident', { id: formData.residentId });
  },
  createMobilizationDiagnostic: async ({ commit, dispatch }, formData) => {
    await apiClient.mutate({
      mutation: CREATE_MOBILIZATION_DIAGNOSTIC,
      variables: { ...formData },
    });
    dispatch('findResident', { id: formData.residentId });
  },
  createResidentTest: async ({ commit, dispatch }, formData) => {
    await apiClient.mutate({
      mutation: CREATE_RESIDENT_TEST,
      variables: { ...formData },
    });
    dispatch('findResident', { id: formData.residentId });
  },
  createWeight: async ({ commit, dispatch }, formData) => {
    await apiClient.mutate({
      mutation: CREATE_WEIGHT,
      variables: { ...formData },
    });
    dispatch('findResident', { id: formData.residentId });
  },
};

const getters = {
  residentsWithRoom(state) {
    return state.residents.filter((resident) => resident.room !== null);
  },
  residentsWithoutRoom(state) {
    return state.residents.filter((resident) => resident.room === null);
  },
  residents: (state) => state.residents,
  resident: (state) => state.resident,
  // Getter with arguments :
  // residentsWithRoomCustomSort: (state, getters) => (sortBy, sortDesc) => {
  //   let order = 'desc';
  //   if (sortDesc === false) {
  //     order = 'asc';
  //   }
  //   return _.orderBy(getters.residentsWithRoom, sortBy, order);
  // },
  // And from Component :
  // return this.$store.getters['residentsWithRoomCustomSort'](sortBy, sortDesc);
};

export default {
  state: residentState,
  mutations,
  actions,
  getters,
};
