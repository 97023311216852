import gql from 'graphql-tag';

export const CREATE_TASK_CATEGORY = gql`
  mutation CREATE_TASK_CATEGORY(
    $title: String!
    $description: String
    $icon: String
    $ehpadId: ID!
  ) {
    createTaskCategory(
      input: {
        title: $title
        description: $description
        icon: $icon
        ehpadId: $ehpadId
      }
    ) {
      taskCategory {
        id
        title
        description
        icon
      }
    }
  }
`;

export const UPDATE_TASK_CATEGORY = gql`
  mutation UPDATE_TASK_CATEGORY(
    $id: ID!
    $title: String!
    $description: String
    $icon: String
  ) {
    updateTaskCategory(
      input: {
        id: $id
        title: $title
        description: $description
        icon: $icon
      }
    ) {
      taskCategory {
        id
        title
        description
        icon
      }
    }
  }
`;
